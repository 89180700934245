import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { Button } from './button';
import { useEligibility } from '../context/base-context';

export default function ConsentModal() {
	const { showOptOutModal, setIsOptOut } = useEligibility();

	return (
		<>
			<Transition appear show={showOptOutModal} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={() => setIsOptOut(false)}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-950/70" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full transform overflow-hidden max-w-xl rounded bg-white p-8 text-left align-middle shadow-xl transition-all">
									<Dialog.Title
										as="h3"
										className="font-theinhardt font-bold text-base md:text-xl lg:text-3xl"
									>
										Kansspel reclame verbergen
									</Dialog.Title>
									<div className="mt-2">
										<p className="font-theinhardt font-normal text-lg leading-relaxed">
											Weet je zeker dat je kansspelreclame en gerelateerde content wilt verbergen
											in deze app? Je keuze hier wordt direct doorgevoerd.
										</p>
									</div>

									<div className="mt-4">
										<div className="mt-6 flex justify-start gap-4">
											<Button
												onClick={() => setIsOptOut(false)}
												size="small"
												className="!normal-case !leading-snug"
											>
												Uitingen tonen
											</Button>
											<Button
												onClick={() => setIsOptOut(true)}
												size="small"
												className="!normal-case !leading-snug"
											>
												Uitingen verbergen
											</Button>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
}
