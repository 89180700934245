import React from 'react';

import { iconPaths } from './Icon-paths';

export type Icons = keyof typeof iconPaths;

interface IProps extends React.SVGAttributes<SVGElement> {
	icon: Icons;
	size: number;
	color: string;
	id?: string;
}

const Icon = ({ icon, size = 18, color, id }: IProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			fill={color}
			viewBox="0 0 24 24"
			id={id}
		>
			<path d={iconPaths[icon]} />
		</svg>
	);
};

export default Icon;
