import React, { useEffect } from 'react';
import { ApolloProvider } from '@apollo/client/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Bugsnag from '@bugsnag/js';
import { initAppClient, initAppServer } from '../lib/setup';
import client from '../lib/apolloClient';
import config from '../lib/config';
import AgeModal from '../components/age-modal';
import { BaseAppContextProvider, useEligibility } from '../context/base-context';

import type { AppProps } from 'next/app';

import '../styles/index.css';
import ConsentModal from '@/components/consent-modal';

initAppServer();

const BugsnagErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

function App({ Component, pageProps }: AppProps) {
	const { showModal, showOptOutModal } = useEligibility();

	useEffect(() => {
		initAppClient();
	}, []);

	return (
		<BugsnagErrorBoundary>
			<ApolloProvider client={client}>
				<BaseAppContextProvider>
					<GoogleReCaptchaProvider reCaptchaKey={config.recaptchaSiteKey}>
						<Component {...pageProps} />
						{showModal && <AgeModal />}
						{showOptOutModal && <ConsentModal />}
					</GoogleReCaptchaProvider>
				</BaseAppContextProvider>
			</ApolloProvider>
		</BugsnagErrorBoundary>
	);
}

export default App;
