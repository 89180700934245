import React from 'react';
import Icon, { Icons } from './icon/Icon';

interface IProps {
	as?: React.ElementType;
	primary?: boolean;
	size?: 'small' | 'medium' | 'large';
	label?: string;
	onClick?: () => void;
	icon?: Icons;
	className?: string;
	type?: 'submit' | 'button' | 'reset';
	disabled?: boolean;
	children?: React.ReactNode;
}

export const Button = ({
	primary = false,
	size = 'medium',
	label,
	icon,
	className,
	as = 'button',
	type,
	disabled,
	children,
	...props
}: IProps) => {
	const mode = primary ? 'kkd-button--primary' : 'kkd-button--secondary';
	const hasIcon = icon ? 'flex items-center' : 'inline-block';
	let buttonClassName = className || '';
	return (
		<button
			type={as === 'button' ? type : undefined}
			className={[hasIcon, 'kkd-button', `kkd-button--${size}`, ` ${buttonClassName}`, mode].join(' ')}
			disabled={disabled}
			{...props}
		>
			{label && <span className={icon ? 'inline-block mr-3' : ''}>{label}</span>}
			{children}
			{icon && (
				<Icon
					icon={icon}
					color={primary ? 'white' : 'text-black-500'}
					size={size === 'small' ? 16 : 18}
					className="inline-block"
				/>
			)}
		</button>
	);
};
