import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import config from './config';
import { relayStylePagination } from '@apollo/client/utilities';

const httpLink = createHttpLink({
	uri: `${config.apiUrl}/graphql`,
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        posts: relayStylePagination(),
      },
    },
  },
});

const client = new ApolloClient({
	link: httpLink,
	cache,
});

export default client;
