import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

import { useEligibility } from '../context/base-context';
import { Button } from './button';

export default function AgeModal() {
	const { setEligibility, showModal } = useEligibility();

	return (
		<>
			<Transition appear show={showModal} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={() => setEligibility(false)}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-950/70" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full transform overflow-hidden max-w-xl rounded bg-white p-8 text-left align-middle shadow-xl transition-all">
									<Dialog.Title
										as="h3"
										className="font-theinhardt font-bold text-base md:text-xl lg:text-3xl"
									>
										Mogen we je leeftijd weten?
									</Dialog.Title>
									<div className="mt-2">
										<p className="font-theinhardt font-normal text-lg leading-relaxed">
											Om content en uitingen over online kansspelen volgens wet- en regelgeving te
											tonen, willen we zeker weten dat je oud genoeg bent. Je kan hoe dan ook onze
											website bezoeken.
										</p>
									</div>

									<div className="mt-4">
										<div className="mt-6 flex justify-start gap-4">
											<Button
												onClick={() => setEligibility(true)}
												size="small"
												className="!normal-case !leading-snug"
											>
												Ik ben 24 jaar of ouder
											</Button>
											<Button
												onClick={() => setEligibility(false)}
												size="small"
												className="!normal-case !leading-snug"
											>
												Ik ben jonger dan 24 jaar
											</Button>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	);
}
